<template lang="pug">
  .accounts
    v-layout.wrapper.accounts__portfolio(justify-space-between)
      .accounts__title {{ $t('common.portfolio') }}
      .accounts__title {{ $t('common.reserved') }}
    v-layout.inner_wrap(justify-space-between)
      .accounts__total #[span(v-html="currencySymbol")] {{ portfolioBalance | roundedNumber(2, $i18n.locale) }}
      .accounts__total #[span(v-html="currencySymbol")] {{ reservedTotal | roundedNumber(2, $i18n.locale) }}
    .accounts__currencies
      v-layout.wrapper
        v-flex.accounts__title(xl1, lg1, md1, sm1, xs12) {{ $t('common.coins') }}
      .accounts__list.inner_wrap(v-for="(item, index) in sortedCurrencies", :key="index")
        WalletCurrency(
          :currency="item",
          @clickFavorite="toggleFavorite",
          @clickBnts="handleAccountClick")
</template>

<script>
import { mapState } from 'vuex';
import WalletCurrency from '@/components/WalletCurrency';
import WalletGraph from '@/components/WalletGraph';
import SvgGraph from '@/components/svg/SvgGraph';

export default {
  components: {
    WalletCurrency,
    WalletGraph,
    SvgGraph
  },
  props: {
    baseCurrencyCode: { type: String, required: true }
  },
  data() {
    return {
      showGraph: false,
      period: 'fluctuationDay',
      chartData: [
        {
          y: 2.92589,
          x: '2019-02-13T00:00:00Z'
        },
        {
          y: 0.92589,
          x: '2019-02-13T00:30:00Z'
        },
        {
          y: 0.57538,
          x: '2019-02-13T01:00:00Z'
        },
        {
          y: 0.67538,
          x: '2019-02-13T01:30:00Z'
        },
        {
          y: -0.84769,
          x: '2019-02-13T02:00:00Z'
        },
        {
          y: -1.34769,
          x: '2019-02-13T02:30:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T03:00:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T03:30:00Z'
        },
        {
          y: 4.0938,
          x: '2019-02-13T04:00:00Z'
        },
        {
          y: 2.34769,
          x: '2019-02-13T04:30:00Z'
        },
        {
          y: 1.57538,
          x: '2019-02-13T05:00:00Z'
        },
        {
          y: 0.34769,
          x: '2019-02-13T05:30:00Z'
        },
        {
          y: 0.57538,
          x: '2019-02-13T06:00:00Z'
        },
        {
          y: -0.94769,
          x: '2019-02-13T06:30:00Z'
        },
        {
          y: -0.57538,
          x: '2019-02-13T07:00:00Z'
        },
        {
          y: -0.57538,
          x: '2019-02-13T07:30:00Z'
        },
        {
          y: 0.23663,
          x: '2019-02-13T08:00:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T08:30:00Z'
        },
        {
          y: 3.28065,
          x: '2019-02-13T09:00:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T09:30:00Z'
        },
        {
          y: 0.53764,
          x: '2019-02-13T10:00:00Z'
        },
        {
          y: 0,
          x: '2019-02-13T10:30:00Z'
        },
        {
          y: -0.86699,
          x: '2019-02-13T11:00:00Z'
        },
        {
          y: -1.34769,
          x: '2019-02-13T11:30:00Z'
        },
        {
          y: -2.16739,
          x: '2019-02-13T12:00:00Z'
        },
        {
          y: -1.34769,
          x: '2019-02-13T12:30:00Z'
        },
        {
          y: 0.09217,
          x: '2019-02-13T13:00:00Z'
        },
        {
          y: 0.34769,
          x: '2019-02-13T13:30:00Z'
        },
        {
          y: 0.95926,
          x: '2019-02-13T14:00:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T14:30:00Z'
        },
        {
          y: 2.57538,
          x: '2019-02-13T15:00:00Z'
        },
        {
          y: 2.77538,
          x: '2019-02-13T15:30:00Z'
        },
        {
          y: 2.87538,
          x: '2019-02-13T16:00:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T16:30:00Z'
        },
        {
          y: 3.62388,
          x: '2019-02-13T17:00:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T17:30:00Z'
        },
        {
          y: 0.57538,
          x: '2019-02-13T18:00:00Z'
        },
        {
          y: 0,
          x: '2019-02-13T18:30:00Z'
        },
        {
          y: 0.27373,
          x: '2019-02-13T19:00:00Z'
        },
        {
          y: -1.34769,
          x: '2019-02-13T19:30:00Z'
        },
        {
          y: -0.33136,
          x: '2019-02-13T20:00:00Z'
        },
        {
          y: -1.34769,
          x: '2019-02-13T20:30:00Z'
        },
        {
          y: -0.57538,
          x: '2019-02-13T21:00:00Z'
        },
        {
          y: -1.34769,
          x: '2019-02-13T21:30:00Z'
        },
        {
          y: -3.1926,
          x: '2019-02-13T22:00:00Z'
        },
        {
          y: -2.34769,
          x: '2019-02-13T22:30:00Z'
        },
        {
          y: -2.0486,
          x: '2019-02-13T23:00:00Z'
        },
        {
          y: 1.34769,
          x: '2019-02-13T23:30:00Z'
        }
      ]
    };
  },
  methods: {
    toggleFavorite(currency) {
      console.log('Toggle fav', currency);
    },
    handleAccountClick(dialogType, currencyCode) {
      this.$emit('buttonClick', dialogType, currencyCode);
    }
  },
  computed: {
    ...mapState('user', ['currencies', 'stats24h']),
    currencySymbol() {
      switch (this.baseCurrencyCode) {
        case 'USD': return '&#x24;';
        case 'EUR': return '&#x20AC;';
        case 'RUB': return '&#x20BD;';
        default: return this.baseCurrencyCode;
      }
    },
    portfolioBalance() {
      return this.$store.getters['user/portfolioBalanceInUserCurrency'];
    },
    reservedTotal() {
      return this.$store.getters['user/reservedTotalInUserCurrency'];
    },
    sortedCurrencies() {
      if (!this.stats24h) return [];

      const mapped = this.currencies.map(c => ({ inUsdt: this.$store.getters['user/getAvailableBalanceInUserCurrency'](c.currencyCode), ...c }));

      mapped.sort((x, y) => {
        if (x.inUsdt < y.inUsdt) return 1;

        if (x.inUsdt > y.inUsdt) return -1;

        return x.currencyCode.localeCompare(y.currencyCode);
      });

      return mapped;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .accounts {
    text-align: left;
    padding-top: 50px;
    overflow: hidden;

    .inner_wrap {
      max-width: 650px;
      margin: 0 auto;

      &:not(.accounts__list) { padding-left: 30px; }
    }

    &__title {
      font-size: 16px;
      color: $light-theme-text;
      font-weight: 600;
    }

    &__total {
      font-size: 40px;
      font-weight: 600;
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 600px) {
        font-size: 24px;
        padding-left: 0;
      }
    }

    &__portfolio {
      padding-right: 250px;
      margin-bottom: 18px;
    }

    &__currencies { margin-top: 20px; }

    &__list {
      width: 100%;
      margin-top: 30px;
    }

    &__fluctuation {
      font-size: 16px;
      font-weight: 600;
      color: $green-light-2;
      margin-left: 20px;

      &.decrease { color: $error-color !important; }

      .v-icon {
        margin-left: 5px;
        padding-bottom: 1px;

        &.down { color: $error-color; }

        &.up { color: $green-light-2; }
      }
    }

    &__graph {
      cursor: pointer;
      color: $blue;
      font-size: 16px;
      font-weight: 600;
      float: right;
      margin-right: 20px;
      position: absolute;
      right: 0;

      & > svg {
        width: 20px;
        height: 20px;
        fill: transparent;
        stroke: $blue;
        margin-right: 13px;
      }

      @media screen and (max-width: 960px) { margin-right: 40px; }

      @media screen and (max-width: 600px) { margin-right: 0; }
    }
  }

  .wrapper {
    @media screen and (max-width: 600px) { flex-wrap: wrap; }
  }

  [v-cloak] { display: none; }

  @media screen and (max-width: 1260px) {
    .accounts__portfolio { padding-right: 20px; }

    .inner_wrap {
      max-width: 1140px !important;

      &:not(.accounts__list) { padding: 0 20px !important; }
    }
  }
</style>
