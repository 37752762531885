<template lang="pug">
  .graph
    .wrapper
      v-flex.graph__tip(xl8, lg8, md8, sm11, xs12, offset-xl1, offset-lg1, offset-md1, offset-sm1)
      .graph__menu
        span.graph__menu__item(
          v-for="(item, index) in periods",
          :key="index",
          :class="{active: index === period}",
          @click="switchPeriod(index)") {{ item }}
      canvas(ref="chart", width="600")
    .graph__close(@click="$emit('closeGraph')")
      SvgPlus
</template>

<script>
import Chart from 'chart.js';
import SvgPlus from '@/components/svg/SvgPlus';

export default {
  components: { SvgPlus },
  props: {
    numbers: { type: Array, required: true }
  },
  data() {
    return {
      chart: undefined,
      period: 0,
      periods: [
        'Day',
        'Week',
        'Month',
        'All time'
      ]
    };
  },
  watch: {
    numbers(value) {
      this.chart.data.datasets[0].data = value;
      this.chart.update();
    }
  },
  methods: {
    switchPeriod(periodIndex) {
      this.period = periodIndex;
      this.chart.update();
    },
    getChartXAxes(datetime) {
      const date = new Date(datetime);

      if (this.period === 0) {
        const hours = date.getHours();
        let minutes = date.getMinutes();
        if (minutes.toString().length < 2) minutes = `0${minutes}`;

        return `${hours}:${minutes}`;
      }

      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();

      return `${months[month]} ${day}, ${year}`;
    }
  },
  mounted() {
    this.chart = new Chart(this.$refs.chart.getContext('2d'), {
      type: 'line',
      maintainAspectRatio: false,
      data: {
        labels: this.numbers,
        datasets: [{
          data: this.numbers,
          backgroundColor: '#f4f4f4',
          borderColor: '#F2994A',
          borderWidth: 2,
          fill: false
        }]
      },
      options: {
        tooltips: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#fff',
          titleFontColor: '#828282',
          bodyFontColor: '#828282',
          displayColors: false
        },
        hover: {
          mode: 'index',
          intersect: false
        },
        elements: { point: { radius: 0, hoverRadius: 0 }, line: { tension: .1 } },
        layout: {
          padding: { top: 100, bottom: 50 }
        },
        legend: { display: false },
        scales: {
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 4,
                callback: value => this.getChartXAxes(value.x),
                padding: 15,
                showLabelBackdrop: false,
                maxRotation: 0,
                minRotation: 0
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: '#f4f4f4',
                color: '#E0E0E0'
              },
              offset: true
            }
          ],
          yAxes: [
            {
              ticks: { maxTicksLimit: 7 },
              gridLines: {
                display: false,
                drawBorder: false,
                zeroLineColor: '#f4f4f4'
              }
            }
          ]
        },
        onResize: (chart, newSize) => {
          if (newSize.width < 623) {
            chart.aspectRatio = 1;
          } else {
            chart.aspectRatio = 2;
          }
        }
      }
    });
  },
  destroyed() {
    this.chart.destroy();
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .graph {
    position: relative;
    background-color: $grey-light4;
    padding: 50px 0;

    &__tip {
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background-color: $grey-light4;
        position: absolute;
        right: 25px;
        top: -66px;
        z-index: 5;
        transform: rotate(45deg);
        border-radius: 10px;
      }
    }

    &__menu {
      text-align: right;

      &__item {
        font-size: 16px;
        font-weight: 600;
        margin-right: 28px;
        cursor: pointer;

        &.active { color: $orange-2; }

        @media screen and (max-width: 400px) { margin-right: 15px; }
      }

      @media screen and (max-width: 1264px) { margin-right: 40px; }
    }

    &__close {
      position: absolute;
      top: 50px;
      right: 40px;
      width: 24px;
      height: 24px;
      min-width: 24px;
      transform: rotate(45deg);
      opacity: .54;

      svg { cursor: pointer; }
    }
  }
</style>
