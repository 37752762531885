<template lang="pug">
  img.coin-icon(
    v-if="name.length",
    :src="`https://kickex.com/static/coins/${name.toLowerCase()}.png`",
    :class="name.toLowerCase()")
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'KICK'
    }
  }
};
</script>

<style scoped lang="scss">
.coin-icon {
  max-width: 100px;

  $white-icons: kick, kex, eth, rub, gbp, usd, eur, try, uah;
  @each $icon in $white-icons {
    .dark &.#{$icon} {
      filter: invert(70%);
    }
  }
}
</style>
