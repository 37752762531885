<template lang="pug">
  v-layout.currency(
    align-center,
    justify-space-between,
    wrap)
    v-layout(align-center)
      .currency__logo
        CoinIcon(:name="currency.currencyCode")
      .currency__name {{ currency.currencyCode }} #[p {{ currency.currencyName }}]
    .currency__fluctuation(v-if="priceChange", :class="{ decrease: +priceChange < 0 }") {{ priceChange }}
      v-icon.down(v-if="+priceChange < 0", size="15") arrow_downward
      v-icon.up(v-if="+priceChange > 0", size="15") arrow_upward
    .currency__balance
      .currency__balance__base(:class="{zero: +availableBalance === 0}")
        span ${{ currency.inUsdt | roundedNumber(2, $i18n.locale) }}
      span {{ availableBalance  | roundedNumber(currencyPrecision, $i18n.locale) }}
    .currency__buttons(:class="{ hidden: currency.currencyCode === 'KEX' }")
      v-btn.main_button(@click.stop="$emit('clickBnts', 1, currency.currencyCode)") Deposit
      a.v-btn.main_button.send(:href="withdrawUrl") {{ $t('actions.withdraw') }}
</template>

<script>
import SvgStar from '@/components/svg/SvgStar';
import SvgPlus from '@/components/svg/SvgPlus';
import CoinIcon from '@/components/CoinIcon';
import { mapState } from 'vuex';

export default {
  components: { SvgStar, SvgPlus, CoinIcon },
  props: {
    currency: { type: Object, required: true }
  },
  data() {
    return {
      currencyPrecision: 8
    };
  },
  computed: {
    ...mapState('user', ['stats24h', 'currencies']),
    availableBalance() {
      return this.$store.getters['user/getAvailableBalance'](this.currency.currencyCode);
    },
    withdrawUrl() {
      return `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/funds/withdrawals`;
    },
    priceChange() {
      const pair = this.stats24h.find(i => i.pairName.split('/')[0] === this.currency.currencyCode && i.pairName.split('/')[1] === 'USDT');

      if (!pair || !Number(pair.changePrice)) return '';

      return Number(pair.changePrice).toFixed(2);
    }
    // currencyPrecision() {
    //   const currency = this.currencies.find(i => i.currencyCode === this.currency.currencyCode);

    //   return currency ? currency.e : 2;
    // }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .currency {
    padding: 20px 0 20px 30px;
    transition: all .3s $easing;
    position: relative;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $light-theme-line;

      .currency__favorite:not(.active) svg {
        fill: transparent;
        stroke: $black;
      }

      .currency__buttons {opacity: 1; }

      &::after { opacity: 1; }
    }

    &::after {
      transition: opacity .3s $easing;
      content: '';
      height: 100%;
      width: 800px;
      background-color: $light-theme-line;
      position: absolute;
      right: -800px;
      opacity: 0;
    }

    &__favorite {
      width: 22px;
      height: 22px;
      fill: $light-theme-background;
      stroke-linejoin: round;
      stroke: transparent;
      stroke-width: 2px;
      margin-right: 20px;
      cursor: pointer;

      & > svg { transition: all .3s $easing; }

      &.active { fill: $yellow !important; }

      @media screen and (max-width: 400px) { margin-right: 10px; }
    }

    &__logo {
      width: 40px;
      height: 40px;
      min-width: 40px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 23px;

      &.default { background: $blue; }

      & > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: contain;
      }

      @media screen and (max-width: 400px) { margin-right: 10px; }
    }

    &__name {
      font-size: 16px;
      font-weight: 600;

      & > p {
        font-size: 12px;
        color: $grey-dark;
        margin-bottom: 0;
      }
    }

    &__fluctuation {
      font-size: 12px;
      font-weight: 600;
      color: $green-light-2;
      text-align: right;

      .v-icon {
        margin-left: 7px;

        &.down { color: $error-color; }

        &.up { color: $green-light-2; }
      }

      &.decrease { color: $error-color !important; }

      @media screen and (max-width: 600px) { margin-right: 15px; }

      @media screen and (max-width: 350px) {
        width: 100%;
        text-align: left;
        margin-top: 20px;
      }
    }

    &__balance {
      text-align: right;
      font-size: 12px;
      color: $light-theme-text;
      font-weight: 600;
      min-width: 118px;

      &__base {
        font-size: 16px;
        color: $sub-black;

        &.zero { color: $light-theme-text; }

        @media screen and (max-width: 350px) {
          display: inline-block;
          margin-right: 8px;
        }
      }

      @media screen and (max-width: 600px) {
        margin-right: 0;
        min-width: 0;
      }

      @media screen and (max-width: 350px) {
        width: 100%;
        text-align: left;
      }
    }

    &__buttons {
      opacity: 0;
      transition: opacity .3s $easing;
      margin-left: 18px;
      position: absolute;
      left: 100%;
      top: calc(50% - 19px);
      display: flex;
      z-index: 10;

      &.hidden { visibility: hidden; }

      .main_button {
        padding: 8px 18px !important;
        font-size: 18px !important;

        transition: none !important;

        &.send {
          background-color: $white !important;
          color: $primary-blue-color !important;
          margin-left: 11px;
          box-shadow: 0px 8px 22px $light-theme-08, 0px 0px 6px $light-theme-05;

          &.hidden { visibility: hidden; }
        }
      }

      @media screen and (max-width: 960px) {
        width: 100%;
        margin-top: 20px;

        .main_button {
          margin-left: 0;
          font-size: 14px !important;
        }
      }
    }

    &__close {
      width: 24px;
      height: 24px;
      min-width: 24px;
      transform: rotate(45deg);
      opacity: .54;
      margin-left: 40px;
      z-index: 10;

      @media screen and (max-width: 960px) {
        position: absolute;
        bottom: -26px;
        right: 27px;
      }
    }

    @media screen and (max-width: 960px) { flex-wrap: wrap; }

    @media screen and (max-width: 460px) { padding: 8px 10px; }
  }

  @media screen and (max-width: 1260px) {
    .currency { padding: 20px !important; }
    .currency__buttons { position: static !important; }
  }

  @media screen and (max-width: 600px) {
    .currency__buttons {
      margin-left: 0;
      display: flex;

      & > * { flex-grow: 1; }

      .send { margin-left: 23px !important; }
    }
  }
</style>
